import { ThemeUIStyleObject } from 'theme-ui'

export const text: Record<string, ThemeUIStyleObject> = {
  default: {
    fontFamily: 'body',
    color: 'text',
    fontWeight: 'body',
    fontSize: [2, 3],
  },
  error: {
    color: 'red',
    py: 2,
  },
  heading: {
    fontSize: 3,
  },
  legal: {
    variant: 'text.default',
    fontSize: 1,
  },
  paragraph: {
    variant: 'text.default',
    marginY: 2,
  },
  caps: {
    textTransform: 'uppercase',
    letterSpacing: '0.2em',
    marginY: 2,
  },
  heading1: {
    variant: 'text.default',
    width: 'fit-content',
    letterSpacing: 2,
    fontSize: 5,
    fontFamily: 'heading',
    fontWeight: 'heading',
    textTransform: 'uppercase',
    position: 'relative',
    my: 2,
  },
  heading2: {
    variant: 'text.default',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    color: 'primary',
    fontSize: [4, 5],
    mt: 4,
  },
  heading3: {
    fontWeight: 'heading',
    color: 'primary',
    fontFamily: 'heading',
    fontSize: 4,
    mt: 4,
  },
  heading4: {
    fontWeight: 'heading',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontSize: 3,
    my: 2,
  },
  hero: {
    background: 'neutral.900',
    paddingX: 3,
    paddingY: 2,
    width: 'fit-content',
    letterSpacing: 8,
    fontSize: [4, 6],
    fontFamily: 'bebas',
    fontWeight: 'heading',
    textTransform: 'uppercase',
    position: 'relative',
    my: 2,
  },
}
