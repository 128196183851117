import { ColorModesScale } from 'theme-ui'

const colorScales: Record<string, ColorModesScale> = {
  red: {
    500: '#cc0000',
  },
  neutral: {
    0: '#ffffff',
    100: '#f9f9f9',
    200: '#f0f0f0',
    300: '#eeeeee',
    400: '#ede9e6',
    500: '#aeaeb2',
    600: '#636366',
    700: '#333',
    800: '#222',
    900: '#1c1c1c',
  },
}

export const colors: ColorModesScale = {
  text: colorScales.neutral['0'],
  background: '#222',
  primary: colorScales.red['500'],
  secondary: colorScales.neutral['600'],
  accent: colorScales.red['500'],
  ...colorScales,
}
