import { ThemeUIStyleObject } from 'theme-ui'

export const forms: Record<string, ThemeUIStyleObject> = {
  input: {
    bg: 'neutral.300',
    borderColor: 'neutral.900',
    padding: 3,
    width: '100%',
    outline: 'none',
    fontSize: 3,
    fontFamily: 'body',
    color: 'primary',
    borderRadius: 0,
    my: 2,
    '&:-webkit-autofill, &:-webkit-autofill:hover': {
      boxShadow: 'none',
      bg: 'neutral.300',
      color: 'neutral.800',
      padding: 3,
      fontSize: 4,
      fontFamily: 'body',
    },
  },
  select: {
    bg: 'neutral.300',
    color: 'neutral.800',
    borderRadius: 0,
    p: 3,
    my: 2,
  },
  textarea: {
    borderRadius: 0,
    my: 2,
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 2,
    bg: 'neutral.300',
    color: 'neutral.800',
    p: 3,
  },
  label: {
    fontFamily: 'body',
    fontWeight: 'normal',
    fontSize: 2,
  },
}
