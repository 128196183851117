import { Theme, ThemeUIStyleObject } from 'theme-ui'

export const buttons: Record<string, ThemeUIStyleObject> = {
  primary: {
    bg: 'primary',
    borderRadius: 0,
    fontFamily: 'body',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    my: 2,
    py: 2,
    px: 4,
  },
  language: {
    all: 'unset',
    fontWeight: 'bold',
    fontSize: 3,
    color: 'neutral.600',
    cursor: 'pointer',
  },
  languageActive: {
    variant: 'buttons.language',
    color: 'primary',
  },
}
